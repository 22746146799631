import React, { useEffect, useMemo, useState } from 'react';
import defaultTheme from './style/materialTheme';
import SnackbarNotifier from './modules/root/SnackbarNotifier';
import { useAppDispatch, useAppSelector } from './@types/redux';
import * as localStorageService from './service/localStorageService';
import './style/app.scss';
import { initializeInterceptor } from './service/http';
import EmployeeNrDialog from './modules/component/dialog/EmployeeNrDialog';
import PasswordDialog from './modules/component/dialog/PasswordDialog';
import { ThemeProvider } from '@mui/material';
import AuthActions from './store/auth/actions';
import GeneralThunk from './store/general/thunk';
import AuthThunk from './store/auth/thunk';
import useFontAwesome from './hooks/useFontAwesome';
import { IUserToken } from './@types/model/auth/userToken/userToken';
import DataThunks from './store/data/thunk';
import DataActions from './store/data/actions';
import { useLocation } from 'react-router-dom';
import PasswordRecovery from './modules/root/PasswordRecovery';
import { IPasswordRecoveryFormValues } from './@types/model/auth/password/passwordRecoveryFormValues';
import PasswordRecoveryModelHelper from './modules/root/helper/passwordRecoveryModelHelper';
import { Loader, Login, IGoogleLoginSuccess, IGoogleLoginError, IOptionType } from '@zz2/zz2-ui';
import { VERSION } from './version';
import navActions from './store/nav/actions';
import NavFrame from './modules/root/NavFrame';
import { OptionType } from './@types/model/optionType';

const App = () : JSX.Element => {
    useFontAwesome();
    const env = ENV_NAME === 'production' ? 'ZZ2 FARM' : 'ZZ2 FARM QA';
    const dispatch = useAppDispatch();
    const location = useLocation();
    const [isLoading, setIsLoading] = useState<boolean>(false);
    const isLoggingIn = useAppSelector<boolean>(x => x.auth.isLoggingIn);
    const isLoggingOut = useAppSelector<boolean>(x => x.auth.isLoggingOut);
    const session = useAppSelector<null | IUserToken>(x => x.auth.session);
    const isMasterDataSyncRunning = useAppSelector(x => x.data.masterDataSyncIsLoading);
    const isNavDrawerOpen = useAppSelector(x => x.general.isNavDrawerOpen);
    const showRecoveryScreen = location.pathname === '/reset-password';


    /*================================================================================================================
     *                                                  Effects
     * ==============================================================================================================*/

    useEffect(() => {
        dispatch(AuthActions.setLoggingIn(true));
        localStorageService.onSessionChanged(async (user) => {
            if (user) {

                /* Logged In */
                initializeInterceptor(user.token, onUnauthenticated, onUnauthorized, onConnectionError);
                dispatch(AuthActions.setSession(user));
                dispatch(AuthActions.setLoggedIn(true));
                dispatch(AuthActions.setLoggingIn(false));

                // This call is being awaited since it needs to finish before any other loading of data
                dispatch(DataActions.setIsMasterDataSyncRunning(true));
                dispatch(GeneralThunk.showInfoSnackbar('Master data sync in progress.'));
                await dispatch(DataThunks.syncMasterData());
                dispatch(GeneralThunk.showSuccessSnackbar('Master data sync completed.'));
                dispatch(DataActions.setIsMasterDataSyncRunning(false));

            } else {

                /* Logged Out or Not yet logged in */
                initializeInterceptor('', onUnauthenticated, onUnauthorized, onConnectionError);
                dispatch(AuthActions.setLoggedIn(false));
                dispatch(AuthActions.setSession(null));
                dispatch(AuthActions.setLoggingIn(false));
            }
        });

    }, []);

    useEffect(() => {
        
        if (session?.user.divisionIds !== null && session?.user.divisionIds.length === 1) {
            const selectedDivisions : Array<IOptionType> = session.user.userDivisions
                .filter(x => x.isActive && x.division.isActive)
                .map(x => OptionType.fromDivision(x.division));
            localStorageService.setUserSelectedDivisionsLocalStorage(selectedDivisions);
            dispatch(DataActions.setSelectedUserDivisionIds(session.user.divisionIds));
        } else {
            const selectedDivisions = localStorageService.getUserSelectedDivisionsLocalStorage();

            if (selectedDivisions.length > 0) {
                dispatch(DataActions.setSelectedUserDivisionIds(selectedDivisions.map(x => x.value)));
            }
        }
        if (session?.user.departmentIds !== null && session?.user.departmentIds.length === 1) {
            const selectedDepartments = session.user.userDepartments
                .filter(x => x.isActive && x.department.isActive)
                .map(x => OptionType.fromDepartment(x.department));
            localStorageService.setUserSelectedDepartmentsLocalStorage(selectedDepartments);
            dispatch(DataActions.setSelectedUserDepartmentIds(session.user.departmentIds));
        } else {
            const selectedDepartments = localStorageService.getUserSelectedDepartmentsLocalStorage();

            if (selectedDepartments.length > 0) {
                dispatch(DataActions.setSelectedUserDepartmentIds(selectedDepartments.map(x => x.value)));
            }
        }
    }, [session, session?.user, session?.user.divisionIds, session?.user.departmentIds]);

    /*================================================================================================================
     *                                                  Handler Methods
     * ==============================================================================================================*/

    const onUnauthenticated = (error : any) : void => {
        initializeInterceptor('', onUnauthenticated, onUnauthorized, onConnectionError);
        dispatch(GeneralThunk.showErrorSnackbar({
            defaultMessage: 'Unauthenticated.',
            e: error,
        }));

        dispatch(AuthThunk.logOut());
    };

    const onUnauthorized = (error : any) : void => {
        dispatch(GeneralThunk.showErrorSnackbar({
            defaultMessage: 'Insufficient rights.',
            e: error,
        }));
    };

    const onConnectionError = () : void => {
        dispatch(GeneralThunk.showErrorSnackbar({
            defaultMessage: 'Connection error.',
        }));
    };

    const manualLogin = async (emailOrUsername : string, password : string) : Promise<void> => {
        setIsLoading(true);
        dispatch(AuthThunk.manualLogIn({ emailOrUsername, password }));
    };

    const requestForgottenPassword = async (recoveryEmailOrUsername : string) : Promise<void> => {
        setIsLoading(true);
        await dispatch(AuthThunk.requestForgottenPassword({ emailOrUsername: recoveryEmailOrUsername }));
        setIsLoading(false);
    };

    const onGoogleLogInSuccess = async (response : IGoogleLoginSuccess) : Promise<void> => {
        const res = await dispatch(AuthThunk.logIn({
            code: response.code,
        })).unwrap();

        if (res) {
            navActions.navReplace('/home');
        }
    };

    const onGoogleLogInFailure = (response : IGoogleLoginError) : void => {
        dispatch(GeneralThunk.showErrorSnackbar({
            defaultMessage: response.error ?? 'Login Error',
            e: response,
        }));
        dispatch(AuthActions.setLoggingIn(false));
    };


    /*================================================================================================================
     *                                                  Form Values
     * ==============================================================================================================*/


    const getInitialPasswordFormValues = useMemo<IPasswordRecoveryFormValues>(() => {
        return PasswordRecoveryModelHelper.createFormValues();
    }, []);


    /*================================================================================================================
     *                                                  Render Methods
     * ==============================================================================================================*/

    const renderApp = useMemo<React.ReactElement>(() => {
        if (isLoggingIn || isLoggingOut) {
            return <Loader/>;
        }
        
        if (session) {
            return (
                <NavFrame />
            );
        }

        return showRecoveryScreen 
            ? <PasswordRecovery initialFormValues={getInitialPasswordFormValues}/> 
            : <Login
                env={env}
                version={VERSION.version}
                isLoading={isLoading}
                isLoggingIn={isLoggingIn}
                manualLogIn={manualLogin}
                requestForgottenPassword={requestForgottenPassword}
                onGoogleSignInSuccess={onGoogleLogInSuccess}
                onGoogleSignInFailure={onGoogleLogInFailure}
            />;

    }, [isLoggingIn, isLoggingOut, session, isMasterDataSyncRunning, showRecoveryScreen, isNavDrawerOpen]);

    return (
        <ThemeProvider theme={defaultTheme}>
            {renderApp}
            <SnackbarNotifier />
            {
                !!session &&
                <EmployeeNrDialog open={!session.user.employeeNr} />
            }
            {
                !!session &&
                <PasswordDialog open={!session.user.password} />
            }
        </ThemeProvider>
    );
};

export default App;
